<template>
  <div id="trending" class="trending mt-4 mr-0">
    <h4 class="my-2">
      <b-icon-graph-up variant="danger" font-scale="1"> </b-icon-graph-up>
      Trending <span style="color: #de3e4e">Authors</span>
    </h4>
    <hr class="my-1" />
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 variant="info"
            >Trending Authors list</b-button
          >
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body align="left" class="trending__list px-1">
            <b-card-text>
              <div v-for="(author, index) in trendingAuthors" :key="author.id">
                <p class="trending__list-item">
                  <b-icon-star-fill
                    v-if="index === 0"
                    variant="warning"
                    font-scale="1"
                  >
                  </b-icon-star-fill>
                  <b-icon-star-half
                    v-else-if="index === 1"
                    variant="warning"
                    font-scale="1"
                  >
                  </b-icon-star-half>
                  <b-icon-star v-else variant="danger" font-scale="1">
                  </b-icon-star>
                  <b-badge variant="primary">{{ index + 1 }}</b-badge>
                  <router-link
                    :to="{
                      name: 'Author',
                      params: { id: author.id },
                    }"
                    ><span class="trending__list-item__title">
                      {{ author.name }}
                    </span></router-link
                  >—
                  <b-icon-trophy aria-label="trophy"></b-icon-trophy>
                  <b-badge variant="light">{{
                    author.glorified.length
                  }}</b-badge>
                  <b-icon-person-fill
                    aria-label="person-fill"
                  ></b-icon-person-fill>
                  <b-badge variant="light">{{
                    author.followedBy.length
                  }}</b-badge>
                </p>
                <hr class="my-2" />
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BButton,
  BCollapse,
  BBadge,
  BIconTrophy,
  BIconPersonFill,
  BIconGraphUp,
  BIconStar,
  BIconStarHalf,
  BIconStarFill,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BButton,
    BCollapse,
    BBadge,
    BIconTrophy,
    BIconPersonFill,
    BIconGraphUp,
    BIconStar,
    BIconStarHalf,
    BIconStarFill,
  },

  data() {
    return {
      trendingAuthors: [],
    };
  },

  async mounted() {
    try {
      this.trendingAuthors = await this.getTredingAuthors();
    } catch (e) {
      if (e.response.status === 401) {
        this.signOut();
      }
    }
  },

  methods: {
    ...mapActions(["getTredingAuthors", "signOut"]),

    getIcon(index) {
      if (index === 0) {
        return "star-fill";
      } else if (index === 1) {
        return "star-half";
      } else {
        return "star";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trending {
  border: 1px solid black;
  min-height: 82vh;
  max-height: 87vh;
  background-color: white;
  border-radius: 0.25rem;

  &__list {
    max-height: 65vh;
    overflow-y: auto;
  }
  p {
    margin-bottom: 0;
  }
  &__list-item {
    font-size: 0.9rem;

    &__title {
      color: black;
      &:hover {
        font-weight: bold;
      }
    }

    &__author {
      font-size: 0.8rem;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #favorite-authors {
    .trending {
      display: none;
    }
  }
}
</style>
